<template>
  <table class="table table-sm table-borderless">
    <thead>
      <tr>
        <th></th>
        <th class="text-right">{{ period.startDate.year() }}</th>
        <th class="text-right">{{ period.startDateMinusYear.year() }}</th>
        <th class="text-right">Variance</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="font-w600">Day Units</td>
        <td class="text-right">{{ data.dayConsumption | numberFormat(2) }}</td>
        <td class="text-right">{{ prevData.dayConsumption | numberFormat(2) }}</td>
        <td class="text-right">
          <PctDiff :value="data.dayConsumption" :prev-value="prevData.dayConsumption" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Night Units</td>
        <td class="text-right">{{ data.nightConsumption | numberFormat(2) }}</td>
        <td class="text-right">{{ prevData.nightConsumption | numberFormat(2) }}</td>
        <td class="text-right">
          <PctDiff :value="data.nightConsumption" :prev-value="prevData.nightConsumption" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Other Units</td>
        <td class="text-right">0.00</td>
        <td class="text-right">0.00</td>
        <td class="text-right">
          <PctDiff :value="0" :prev-value="0" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Available Capacity</td>
        <td class="text-right">0.00</td>
        <td class="text-right">0.00</td>
        <td class="text-right">
          <PctDiff :value="0" :prev-value="0" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Maximum Capacity</td>
        <td class="text-right">{{ data.maxDemand | numberFormat(2) }}</td>
        <td class="text-right">{{ prevData.maxDemand | numberFormat(2) }}</td>
        <td class="text-right">
          <PctDiff :value="data.maxDemand" :prev-value="prevData.maxDemand" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Load Factor</td>
        <td class="text-right">{{ data.peakLoad | numberFormat(2) }}%</td>
        <td class="text-right">{{ prevData.peakLoad | numberFormat(2) }}%</td>
        <td class="text-right">
          <PctDiff :value="data.peakLoad" :prev-value="prevData.peakLoad" comparison-period="year" hide-text />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PctDiff from '@/components/analytics/PctDiff';

export default {
  name: 'HHCapacityInformation',
  components: {
    PctDiff
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    prevData: {
      type: Object,
      required: true
    }
  }
};
</script>
