<template>
  <Chart svg :option="options" />
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import Chart from '@/components/echarts/Chart';

import chartOptions from './options';

export default {
  components: {
    Chart
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },
  computed: {
    options() {
      const daysArray = Array.from({ length: moment(this.period.startDate).endOf('month').date() }, (_, i) => i + 1);

      const seriesArray = daysArray.map(day => {
        const dayData = this.data.filter(item => moment.utc(item.date).date() === day);

        return dayData.reduce((acc, item) => acc + item.consumption, 0);
      });

      const options = merge({}, chartOptions, {
        xAxis: {
          name: 'Day',
          type: 'category',
          data: daysArray
        },
        yAxis: {
          type: 'value',
          name: this.units
        },
        series: {
          type: 'bar',
          name: 'Consumption',
          data: seriesArray
        },
        legend: {
          show: true,
          bottom: 10
        }
      });

      return options;
    }
  }
};
</script>
